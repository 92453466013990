import User from 'models/User';
import abortableFetch from 'services/api/abortableFetch';

export type UserType = 'community' | 'brand';

export const loadUser = (type: UserType = 'community') =>
  abortableFetch<User>(
    `${process.env.REACT_APP_BACKEND_HOST}/api/users/${type}`,
  );

export const loadCurrentUser = () =>
  abortableFetch<User>(
    `${process.env.REACT_APP_BACKEND_HOST}/api/users/current`,
  );
