import React, { ComponentType, FC } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useUser } from "context/user";
import { Skeleton } from "antd";
import { RouteChildrenProps } from "react-router";
import { ACCESS_TOKEN } from "../constants";
import AdminLogin from "components/Admin";
import UnapprovedUserLogin from "components/Login/UnapprovedUserLogin";

export interface Props extends RouteProps {
  component: ComponentType | any;
  name: string;
}

const PrivateRoute: FC<Props> = ({ component: Component, ...routeProps }) => {
  const { user, loading, logoutUser } = useUser();
  return (
    <Skeleton loading={loading} active>
      <Route
        {...routeProps}
        render={(props: RouteChildrenProps) => {
          if (!user) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else if (
            user &&
            user.roles &&
            user.roles.indexOf("PLATFORM_ADMIN") > -1 &&
            (!user.entities || user.entities.length === 0)
          ) {
            return <AdminLogin />;
          } else if (
            user &&
            user.roles &&
            user.roles.indexOf("REGISTERED_USER") > -1 &&
            (!user.entities || user.entities.length === 0)
          ) {
            return <UnapprovedUserLogin />;
          } else if (user && (!user.entities || user.entities.length === 0)) {
            localStorage.removeItem(ACCESS_TOKEN);
            logoutUser();
            return (
              <Redirect
                to={{
                  pathname: "/login-unregistered-user",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          } else {
            return <Component {...props} />;
          }
        }}
      />
    </Skeleton>
  );
};

export default PrivateRoute;
