import { Col, Layout, Row } from "antd";
import React from "react";

import classes from "./LoginAdmin.module.css";

const AdminLogin = () => {
  return (
    <Layout style={{ paddingBottom: "40px" }}>
      <Row>
        <div className={classes.TagLine}>Login Success</div>
      </Row>
      <Row className={classes.Row}>
        <Col xl={6} xs={2}></Col>
        <Col xl={12} xs={20} style={{ textAlign: "center" }}>
          <span>You logged in as Admin</span>
        </Col>
        <Col xl={6} xs={2}></Col>
      </Row>
    </Layout>
  );
};

export default AdminLogin;
