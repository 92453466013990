import React from "react";
import { ACCESS_TOKEN, REDIRECT_BACK_URL } from "../../constants";
import { Redirect } from "react-router-dom";
import { RouteChildrenProps } from "react-router";

const OAuth2RedirectHandler: React.FC<RouteChildrenProps> = (props) => {
  const getUrlParameter = (name: string) => {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    var results = regex.exec(props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const token = getUrlParameter("token");
  const error = getUrlParameter("error");

  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
    const redirectBackUrl = localStorage.getItem(REDIRECT_BACK_URL)
      ? localStorage.getItem(REDIRECT_BACK_URL)
      : "/";
    localStorage.removeItem(REDIRECT_BACK_URL);

    return (
      <Redirect
        to={{
          pathname: `${redirectBackUrl}`,
          state: { from: props.location },
        }}
      />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: props.location,
            error: error,
          },
        }}
      />
    );
  }
};

export default OAuth2RedirectHandler;
