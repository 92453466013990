import { Col, Layout, Row } from "antd";
import { useUser } from "context/user";
import React from "react";

import classes from "./UnapprovedUserLogin.module.css";

const UnapprovedUserLogin = () => {
  const { user } = useUser();

  return (
    <Layout style={{ paddingBottom: "40px" }}>
      <Row>
        <div className={classes.TagLine}>Login Success</div>
      </Row>
      <Row className={classes.Row}>
        <Col xl={6} xs={2}></Col>
        <Col xl={12} xs={20} style={{ textAlign: "center" }}>
          <span>
            Your application is under review. We will send you further
            instructions to email provided
            <span
              style={{ color: "var(--primary-color)" }}
            >{` ${user?.email}`}</span>
          </span>
        </Col>
        <Col xl={6} xs={2}></Col>
      </Row>
    </Layout>
  );
};

export default UnapprovedUserLogin;
