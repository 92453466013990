import { Drawer, Icon } from "antd";

import HeaderAvatar from "components/User/HeaderAvatar";
import { useEntities } from "context/entity";
import { useUser } from "context/user";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";
import { privateRoutes, publicRoutes } from "routes";
import classes from "./TopNav.module.css";

export default () => {
  const { user } = useUser();
  const { entities, current } = useEntities();
  const [drawerVisible, setDrawerVsisible] = useState(false);

  const showDrawer = () => {
    setDrawerVsisible(true);
  };

  const onDrawerClose = () => {
    setDrawerVsisible(false);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const getDrawerHeader = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: "18px" }}>MENU</div>
        <div style={{ width: "100%", textAlign: "end" }}>
          <Icon
            style={{ fontSize: "20px" }}
            type="close"
            onClick={onDrawerClose}
          />
        </div>
      </div>
    );
  };

  if (!user || !user.entities || user.entities.length === 0) {
    return (
      <>
        {isDesktopOrLaptop && (
          <div style={{ display: "flex" }}>
            <div className={classes.PublicNav}>
              <NavLink to={publicRoutes.EarlyAdopterProgram.path}>
                Become an Early Adopter
              </NavLink>
            </div>
            <div className={classes.PublicNav}>
              <NavLink to={publicRoutes.HowItWorks.path}>How it works</NavLink>
            </div>
            {!user && (
              <div className={classes.PublicNav}>
                <NavLink to={publicRoutes.Login.path}>Login</NavLink>
              </div>
            )}
            {user && (
              <HeaderAvatar
                user={user}
                currentEntity={current}
                entities={entities}
              />
            )}
          </div>
        )}
        {isTabletOrMobile && (
          <div>
            <div>
              <Icon
                type="menu"
                className={classes.Hamburger}
                onClick={showDrawer}
              />
            </div>
            <Drawer
              title={getDrawerHeader()}
              placement="right"
              closable={false}
              width="100%"
              onClose={onDrawerClose}
              visible={drawerVisible}
            >
              <div className={classes.PublicNav}>
                <div className={classes.HamburgerItem}>
                  <NavLink
                    to={publicRoutes.EarlyAdopterProgram.path}
                    onClick={onDrawerClose}
                  >
                    Become an Early Adopter
                  </NavLink>
                </div>
                <div className={classes.HamburgerItem}>
                  <NavLink
                    to={publicRoutes.HowItWorks.path}
                    onClick={onDrawerClose}
                  >
                    How it works
                  </NavLink>
                </div>
                <div className={classes.HamburgerItem}>
                  {!user && (
                    <NavLink
                      to={publicRoutes.Login.path}
                      onClick={onDrawerClose}
                    >
                      Login
                    </NavLink>
                  )}
                  {user && (
                    <NavLink to="/logout" onClick={onDrawerClose}>
                      <Icon type="logout" /> Logout
                    </NavLink>
                  )}
                </div>
              </div>
            </Drawer>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className={classes.Navigation}>
        <NavLink to={privateRoutes.Home.path}>
          {privateRoutes.Home.name}
        </NavLink>
        <NavLink to={privateRoutes.Collaborations.path}>
          {privateRoutes.Collaborations.name}
        </NavLink>
        {/* <NavLink to={privateRoutes.Profile.path}>
          {privateRoutes.Profile.name}
        </NavLink> */}
      </div>
      <HeaderAvatar user={user} currentEntity={current} entities={entities} />
    </>
  );
};
