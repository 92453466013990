import React from "react";
import { NavLink } from "react-router-dom";
import { publicRoutes } from "routes";

import classes from "./footer.module.css";

export default () => {
  return (
    <div style={{ display: "flex" }}>
      <div className={classes.LeftContainer}>
        <div>
          <NavLink
            className={classes.Link}
            to={publicRoutes.PrivacyPolicy.path}
          >
            Privacy Policy
          </NavLink>
        </div>
        <div>
          <NavLink
            className={classes.Link}
            to={publicRoutes.TermsOfService.path}
          >
            Terms of Service
          </NavLink>
        </div>
      </div>
      <div>
        <span style={{ textAlign: "end" }}>
          <a className={classes.Copyright} href="/">
            © nucreed 2023
          </a>
        </span>
      </div>
    </div>
  );
};
