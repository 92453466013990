import loadable from "@loadable/component";

// const routes = (
//   <HashRouter>
//     <Application>
//       <Switch>
//         <Redirect exact={true} from="/" to="home" />
//         <Route exact={true} path="/home/" component={HomePageController} />
//         <Route
//           exact={true}
//           path="/account/"
//           component={AccountPageController}
//         />
//         <Route
//           exact={true}
//           path="/collaborations/"
//           component={CollaborationsPageController}
//         />
//         <Route
//           exact={true}
//           path="/collaborations/:collaborationId"
//           component={CollaborationsPageController}
//         />
//         <Route
//           exact={true}
//           path="/profile/"
//           component={ProfilePageController}
//         />
//         <Route
//           exact={true}
//           path="/profile/wizard/:entityType"
//           component={OnBoardingWizardController}
//         />
//         <Route exact={true} path="/store/" component={StorePageController} />
//       </Switch>
//     </Application>
//   </HashRouter>
// );

// export default routes;

export const privateRoutes = {
  Home: {
    name: "Home",
    path: "/home",
    isPrivate: true,
    component: loadable(
      () => import(/* webpackChunkName: "home" */ "pages/Home")
    ),
  },
  CollaborationsDetails: {
    name: "Collaboration ",
    path: "/collaborations/:collaborationId",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "collaborations-details" */ "pages/Collaborations/CollaborationDetails"
        )
    ),
  },
  Collaborations: {
    name: "Collaborations",
    path: "/collaborations",
    isPrivate: true,
    component: loadable(
      () =>
        import(/* webpackChunkName: "collaborations" */ "pages/Collaborations")
    ),
  },
  // Store: {
  //   name: "Store",
  //   path: "/store",
  //   isPrivate: true,
  //   component: loadable(
  //     () => import(/* webpackChunkName: "store" */ "pages/Store")
  //   ),
  // },
  // Account: {
  //   name: "Account",
  //   path: "/account",
  //   isPrivate: true,
  //   component: loadable(
  //     () => import(/* webpackChunkName: "account" */ "pages/Account")
  //   ),
  // },
  // Profile: {
  //   name: "Profile",
  //   path: "/profile",
  //   isPrivate: true,
  //   component: loadable(
  //     () => import(/* webpackChunkName: "profile" */ "pages/Profile")
  //   ),
  // },
  OnBoardingWizard: {
    name: "On Boarding Wizard",
    path: "/profile/wizard/:entity?",
    isPrivate: true,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "profile-wizard" */ "pages/OnBoardingWizard"
        )
    ),
  },
};

export const publicRoutes = {
  Main: {
    name: "Main",
    path: "/",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "login" */ "pages/Main")
    ),
  },
  Login: {
    name: "Login",
    path: "/login",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "login" */ "pages/Login")
    ),
  },
  HowItWorks: {
    name: "How It Works",
    path: "/how-it-works",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "how-it-works" */ "pages/HowItWorks")
    ),
  },
  TellYourStory: {
    name: "Tell Your Story",
    path: "/tell-your-story",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "your-story" */ "pages/TellYourStory")
    ),
  },
  EarlyAdopterProgram: {
    name: "Become Early Adopter",
    path: "/early-adopter-program",
    isPrivate: false,
    component: loadable(
      () =>
        import(/* webpackChunkName: "your-story" */ "pages/EarlyAdopterProgram")
    ),
  },
  HelpToTellAStory: {
    name: "Help To Tell A Story",
    path: "/help-to-tell-a-story",
    isPrivate: false,
    component: loadable(
      () =>
        import(/* webpackChunkName: "tell-a-story" */ "pages/HelpToTellAStory")
    ),
  },
  RegistrationInvite: {
    name: "Register Early Adopter",
    path: "/register/:token",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "tell-a-story" */ "pages/RegistrationInvite"
        )
    ),
  },
  AddUserToEntity: {
    name: "Add User To Entity",
    path: "/register/add-entity/:token",
    isPrivate: false,
    component: loadable(
      () =>
        import(/* webpackChunkName: "tell-a-story" */ "pages/AddUserToEntity")
    ),
  },
  LoginUnregisteredUser: {
    name: "Login Unregistered User",
    path: "/login-unregistered-user",
    isPrivate: false,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "tell-a-story" */ "pages/LoginUnregisteredUser"
        )
    ),
  },
  PrivacyPolicy: {
    name: "Privacy Policy",
    path: "/privacy-policy",
    isPrivate: false,
    component: loadable(
      () => import(/* webpackChunkName: "tell-a-story" */ "pages/PrivacyPolicy")
    ),
  },
  TermsOfService: {
    name: "Terms of Service",
    path: "/terms-of-service",
    isPrivate: false,
    component: loadable(
      () =>
        import(/* webpackChunkName: "tell-a-story" */ "pages/TermsOfService")
    ),
  },
};
