import React, { useState, useEffect } from "react";

import { Modal, List } from 'antd';
import { ModalProps } from "antd/lib/modal";
import classnames from 'classnames';
import { useEntities } from "context/entity";
import Entity from "models/Entity";
import classes from "./EntitySwitcher.module.css";


const EntitySwitcher: React.SFC<ModalProps> = (props) => {
    const { entities, current, setCurrent } = useEntities();
    const [selectedEntity, setSelectedEntity] = useState<Entity | any>({});

    const handleEntitySwitch = (newSelectedEntity: Entity) => {
        selectedEntity !== newSelectedEntity && setSelectedEntity(newSelectedEntity);
    }

    useEffect(() => {
        setSelectedEntity(current);
    }, [current]);

    const handleOk = (e: React.MouseEvent<HTMLElement>) => {
        selectedEntity !== current && setCurrent(selectedEntity);
        props.onOk && props.onOk(e);
    }

    const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
        setSelectedEntity(current);
        props.onCancel && props.onCancel(e);
    }

    return (
        <div>
            <Modal
                title="Switch your current entity"
                visible={props.visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <List
                    size="large"
                    split={false}
                    dataSource={entities}
                    renderItem={entity =>
                        <List.Item
                            className={classnames(classes.EntityItem, { [classes.SelectedEntity]: selectedEntity.id === entity.id })}
                            onClick={() => { handleEntitySwitch(entity) }}>
                            {entity.name}
                        </List.Item>}
                />
            </Modal>
        </div>
    );
}

export default EntitySwitcher;
