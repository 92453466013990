import { useEffect } from "react";
import ReactGA from "react-ga";

import User from "models/User";

export function useGoogleAnalytics(user: User | undefined) {
  const initialize = () => {
    const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY; // Replace with your Google Analytics tracking ID

    trackingId && ReactGA.initialize(trackingId);

    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  const updateUser = () => {
    if (!user) {
      return;
    }

    ReactGA.set({
      userId: user && user.email,
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    });
  };

  initialize();

  useEffect(updateUser, [user]);
}
