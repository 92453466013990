import React, { createContext, useContext, ReactNode, useReducer } from "react";

interface RefetchContextInterface {
  forceUpdate: () => void;
  refetch: boolean;
}

const RefetchContext = createContext<RefetchContextInterface>({
  forceUpdate: () => {},
  refetch: false,
});

export function useRefetchContext() {
  const context = useContext(RefetchContext);
  if (!context) {
    throw new Error("useRefetchContext should be used with RefetchProvider");
  }

  return context;
}

export function RefetchProvider({ children }: { children: ReactNode }) {
  const [refetch, forceUpdate] = useReducer((s) => !s, false);

  return (
    <RefetchContext.Provider
      value={{
        refetch,
        forceUpdate,
      }}
      children={children}
    />
  );
}
