import { EntityProvider } from "context/entity";
import { UserProvider, useUser } from "context/user";
import { RefetchProvider } from "context/refetch";
import PrimaryLayout from "layouts/Primary";
import PrivateRoute from "lib/privateRoute";
import { values } from "rambda";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { privateRoutes, publicRoutes } from "./routes";
import OAuth2RedirectHandler from "lib/oauth2/OAuth2RedirectHandler";
import Main from "pages/Main";
import LogoutHandler from "lib/oauth2/LogoutHandler";
import PublicRoute from "lib/publicRoute";
import User from "models/User";

const privateRoutesArray = values(privateRoutes);
const publicRoutesArray = values(publicRoutes);

const App: React.FC = (props) => {
  return (
    <UserProvider>
      <EntityProvider>
        <RefetchProvider>
          {/* <CollaborationProvider> */}
          <Router>
            <Switch>
              {/* <Route
                exact
                path="/"
                render={() => (
                  <PrimaryLayout>
                    <Main />
                  </PrimaryLayout>
                )}
              ></Route> */}
              {/* <Route
            path="/login"
            render={(loginProps: RouteComponentProps) => (
              <Blank>
                <LoginPage {...loginProps} />
              </Blank>
            )}
          ></Route> */}
              <Route
                path="/oauth2/redirect"
                component={OAuth2RedirectHandler}
              ></Route>
              <Route path="/logout" component={LogoutHandler}></Route>
              <Route path={privateRoutesArray.map(({ path }) => path)}>
                <PrimaryLayout>
                  {privateRoutesArray.map((props) => (
                    <PrivateRoute exact {...props} key={props.name} />
                  ))}
                </PrimaryLayout>
              </Route>
              <Route path={publicRoutesArray.map(({ path }) => path)}>
                <PrimaryLayout>
                  {publicRoutesArray.map((props) => (
                    <PublicRoute exact {...props} key={props.name} />
                  ))}
                </PrimaryLayout>
              </Route>
            </Switch>
          </Router>
          {/* </CollaborationProvider> */}
        </RefetchProvider>
      </EntityProvider>
    </UserProvider>
  );
};

export default App;
