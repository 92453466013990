import React, { ComponentType, FC } from "react";
import { Route, RouteProps } from "react-router-dom";
import { RouteChildrenProps } from "react-router";

export interface Props extends RouteProps {
  component: ComponentType | any;
  name: string;
}

const PublicRoute: FC<Props> = ({ component: Component, ...routeProps }) => {
  return (
        <Route
          {...routeProps}
          render={(props: RouteChildrenProps) => {
            return <Component {...props} />;
          }}
        />
  )
};

export default PublicRoute;
