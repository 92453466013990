import UserModel from "models/User";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { loadCurrentUser, UserType } from "services/api/user";

// TODO rewrite toggle logic after demo
const Key = "USER_TYPE";

const getDefaultUser = (): UserModel | undefined => ({
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  imageUrl: "",
  entities: [],
});

const UserContext = createContext({
  user: getDefaultUser(),
  toggleUser: () => {},
  logoutUser: () => {},
  useAddEntityToUser: (_entityId: string | undefined) => {},
  loading: true,
});

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser should be used with UserProvider");
  }

  return context;
}

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<UserModel | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [userType, setUserType] = useState<UserType | undefined>(
    (localStorage.getItem(Key) as UserType) || "community"
  );

  useEffect(() => {
    const { ready, abort } = loadCurrentUser();
    setLoading(true);
    ready()
      .then((u) => {
        setUser(u);
      })
      .catch((error) => {
        setUser(undefined);
      })
      .finally(() => {
        setLoading(false);
      });

    return abort;
  }, []);

  const useAddEntityToUser = (entityId: string | undefined) => {
    useEffect(() => {
      if (
        entityId &&
        user &&
        (!user.entities ||
          user.entities.findIndex((entity) => entity === entityId) === -1)
      ) {
        const userEntities = user.entities ? user.entities : [];
        userEntities.push(entityId);

        setUser(
          Object.assign({}, user, {
            entities: userEntities,
          })
        );
      }
    }, [entityId]);
  };

  const toggleUser = () => {
    if (userType === "community") {
      localStorage.setItem(Key, "brand");
      setUserType("brand");
      return;
    }
    localStorage.setItem(Key, "community");
    setUserType("community");
  };

  const logoutUser = () => {
    localStorage.removeItem(Key);
    setUserType(undefined);
    setUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{ user, toggleUser, logoutUser, loading, useAddEntityToUser }}
      children={children}
    />
  );
}
