import { ACCESS_TOKEN } from "../../constants";

export const getAuthToken = () =>
  "Bearer " + localStorage.getItem(ACCESS_TOKEN);

export type Options = RequestInit & {
  onRequestStart?: () => void;
  onRequestEnd?: () => void;
};

export type AbortableFetch<T> = {
  abort: () => void;
  ready: () => Promise<T>;
};

export default <T>(
  request: RequestInfo,
  opts: Options = {}
): AbortableFetch<T> => {
  const controller = new AbortController();
  const { signal } = controller;

  const headers = new Headers({
    "Content-Type": "application/json",
    "X-Forwarded-Proto": "https",
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append("Authorization", getAuthToken());
  }

  const defaults = { headers: headers };
  opts = Object.assign({}, defaults, opts);

  return {
    abort: () => controller.abort(),
    ready: async () => {
      try {
        opts.onRequestStart && opts.onRequestStart();

        const data = await fetch(request, { ...opts, signal });
        const json = (await data.json()) as Promise<T>;

        if (!data.ok) {
          return Promise.reject(json);
        }

        return json as Promise<T>;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        opts.onRequestEnd && opts.onRequestEnd();
      }
    },
  };
};
