import Avatar from "antd/lib/avatar";
import Dropdown from "antd/lib/dropdown/dropdown";
import Icon from "antd/lib/icon";
import Menu from "antd/lib/menu";
import classnames from "classnames";
import { getUpperLetters } from "lib/strings";
import Entity from "models/Entity";
import User from "models/User";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import EntitySwitcher from "../EntitySwitcher";
import classes from "./HeaderAvatar.module.css";

interface HeaderAvatar {
  user: User;
  currentEntity?: Entity;
  entities?: Entity[];
}

const HeaderAvatar: React.FC<HeaderAvatar> = ({
  user,
  entities,
  currentEntity,
}) => {
  const [entitySwitcherDialogVisible, setEntitySwitcherDialogVisible] =
    useState<boolean>(false);

  const showModal = () => {
    setEntitySwitcherDialogVisible(true);
  };

  const handleEntitySwitcherDialogOk = (e: any) => {
    setEntitySwitcherDialogVisible(false);
  };

  const handleEntitySwitcherDialogCancel = (e: any) => {
    setEntitySwitcherDialogVisible(false);
  };

  return (
    <>
      {entities && (
        <EntitySwitcher
          visible={entitySwitcherDialogVisible}
          onCancel={handleEntitySwitcherDialogCancel}
          onOk={handleEntitySwitcherDialogOk}
        ></EntitySwitcher>
      )}
      <Dropdown
        overlay={
          <Menu>
            {entities && entities.length > 1 && (
              <Menu.Item key="switch" onClick={showModal}>
                <Icon type="retweet" /> Switch Entity
              </Menu.Item>
            )}
            <Menu.Item key="account">
              <NavLink to="/">
                <Icon type="user" /> Profile
              </NavLink>
            </Menu.Item>
            <Menu.Divider />
            {user && (
              <Menu.Item key="logout">
                <NavLink to="/logout">
                  <Icon type="logout" /> Logout
                </NavLink>
              </Menu.Item>
            )}
          </Menu>
        }
        align={{ offset: [0, -4] }}
      >
        <div
          className={classnames(
            classes.Item,
            classes.Border,
            classes.ItemWithType
          )}
        >
          <Avatar className={classes.Avatar} size={44} src={user.imageUrl}>
            {getUpperLetters(`${user.firstName}${user.lastName}`)}
          </Avatar>
          <div style={{ marginLeft: "15px" }}>
            <div className={classes.EntityName}>
              {currentEntity && currentEntity.name
                ? currentEntity.name
                : `${user.firstName ? user.firstName : ""} ${
                    user.lastName ? user.lastName : ""
                  }`}
            </div>
            <div className={classes.EntityType}>
              {currentEntity && currentEntity.type}
            </div>
          </div>
        </div>
      </Dropdown>
    </>
  );
};

export default HeaderAvatar;
