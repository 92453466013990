import React from "react";
import { RouteChildrenProps, Redirect } from "react-router";
import { ACCESS_TOKEN } from "../../constants";
import { useUser } from "context/user";
import { useEntities } from "context/entity";
import { message } from "antd";

const LogoutHandler: React.SFC<RouteChildrenProps> = props => {
    const { logoutUser } = useUser();
    const { setCurrent: setCurrentEntity } = useEntities();

    const handleLogout = () => {
        localStorage.removeItem(ACCESS_TOKEN);
        logoutUser();
        setCurrentEntity(undefined);
        message.success('You have been safely logged out!');
    }

    handleLogout();

    return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      );
}

export default LogoutHandler;
