export const getUpperLetters = (string: string): string =>
  string.replace(/[a-z\s]+/g, "");

export const capitalize = (string: string): string => {
  const newString = string.toString().toLowerCase();

  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

const randomString = (length: number, chars: string): string => {
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const generateRandomString = (length: number): string => {
  return randomString(
    length,
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  );
};
