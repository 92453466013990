import { Layout } from "antd";
import { ReactComponent as Logo } from "assets/logo.svg";
import TopNav from "components/TopNav";
import React, { memo, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { publicRoutes } from "routes";
import classnames from "classnames";

import classes from "./Primary.module.css";
import NucreedLogo from "components/Logo";
import { useUser } from "context/user";
import User from "models/User";
import { NavLink } from "react-router-dom";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import FooterPanel from "components/FooterPanel";
import { useMediaQuery } from "react-responsive";

type Props = {
  children?: ReactNode;
} & RouteComponentProps;

const style = {
  minHeight: "100vh",
};

const { Header, Content, Footer } = Layout;

const landingVideo = require("./resources/landing_video_compressed.mp4");

const PrimaryLayout = memo(({ children, location }: Props) => {
  const { user, loading } = useUser();
  useGoogleAnalytics(user);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const mobileBackground = require("./resources/landing_4_mobile_right.jpg");

  return (
    <>
      {isDesktopOrLaptop &&
        isTransparentBackground(location.pathname, user, loading) && (
          <video
            autoPlay={true}
            muted
            loop
            id="ladingVideo"
            className={classes.LandingVideo}
          >
            <source src={landingVideo} type="video/mp4" />
          </video>
        )}
      {isTabletOrMobile &&
        isTransparentBackground(location.pathname, user, loading) && (
          <img
            className={classes.LandingVideo}
            src={mobileBackground}
            alt="background"
          />
        )}
      <Layout
        style={style}
        // className={classnames({
        //   [classes.Container]: isTransparentBackground(
        //     location.pathname,
        //     user,
        //     loading
        //   ),
        // })}
      >
        <Header
          className={classnames(classes.Header, {
            [classes.TransparentHeader]: isTransparentBackground(
              location.pathname,
              user
            ),
            [classes.HeaderMobile]: isTabletOrMobile,
          })}
        >
          <NavLink to={publicRoutes.Main.path}>
            <div
              style={{
                display: "flex",
                paddingTop: "20px",
                marginLeft: "-20px",
              }}
            >
              <NucreedLogo style={{ width: "50px" }} />
              <div style={{ paddingLeft: "30px" }}>
                <Logo className={classes.Logo} />
              </div>
            </div>
          </NavLink>
          <TopNav key="topNav" />
        </Header>
        <Layout
          className={classnames(classes.Container, {
            [classes.TransparentContent]: isTransparentBackground(
              location.pathname,
              user,
              loading
            ),
          })}
        >
          <Content className={classes.Content}>{children}</Content>
        </Layout>
        <Footer
          className={classnames(classes.Footer, {
            [classes.TransparentFooter]: isTransparentBackground(
              location.pathname,
              user
            ),
          })}
        >
          <FooterPanel />
        </Footer>
      </Layout>
    </>
  );
});

export default withRouter((props) => <PrimaryLayout {...props} />);

const isTransparentBackground = (
  path: string,
  user?: User,
  loading?: boolean
) => {
  return !user && path === "/" && !loading;
};
