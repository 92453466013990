import Entity from "models/Entity";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { loadEntities } from "services/api/entity";
import { useUser } from "./user";

type EntityProps = {
  children: ReactNode;
};
type EntityContextType = {
  entities?: Entity[];
  current?: Entity;
  setCurrent: (current: Entity | undefined) => void;
};
const EntityContext = createContext<EntityContextType | undefined>({
  current: undefined,
  setCurrent: (current: Entity | undefined) => {},
  entities: undefined,
});

export function useEntities() {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error("useEntities should be used with EntityProvider");
  }

  return context;
}

function useLoadedEntities(ids: string): EntityContextType {
  const [entities, setEntities] = useState<Entity[] | undefined>(undefined);
  const [current, setCurrent] = useState<Entity | undefined>(undefined);

  useEffect(() => {
    if (!ids.length) {
      return;
    }
    const { ready, abort } = loadEntities(ids);
    ready().then((items) => {
      setEntities(items);
      //TODO remove after
      items.length && setCurrent(items[0]);
    });

    return abort;
  }, [ids]);

  return {
    entities,
    current,
    setCurrent: (current: Entity | undefined) => setCurrent(current),
  };
}

export function EntityProvider({ children }: EntityProps) {
  const { user } = useUser();
  const value = useLoadedEntities(
    user && user.entities ? user.entities.join(",") : ""
  );

  return <EntityContext.Provider value={value} children={children} />;
}
